@import "./base/variables";

.formcontainer {
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  background:#FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: min-content;
}

.contentContainer {
  height: calc(-#{$banner-height} - #{$padding} - #{$padding} + 100vh);
  padding: $padding;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;

  div.header {
    padding: 12px 8px;
    font-size: 18px;
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .backBtn {
    color: $primary-background-color;
    height: 20px;
    cursor: pointer;
    margin-right: 6px;
    margin-top: 6px;
    filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(337deg) brightness(105%) contrast(107%);
  }

  div.actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  div.title {
    align-items: center;
    display: flex;

    .addBtn {
      height: 20px;
      cursor: pointer;
      margin-left: 6px;
      margin-top: 6px;
    }

    .delete {
      margin-left: 12px;
    }

    input {
      text-align: start;
      border: 0px;
      font-size: 18px;
      color: $primary-background-color;
      margin-top: 2px;
    }
  }

  div.search {
    padding: 12px 8px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
  }

  div.content {
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    overflow: auto;
    flex: 1;
  }
}

hr {
  width: 100%;
  border-top: #121212 solid 0.5px;
  opacity: 30%;
}

.notesContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  max-width: 412px;
  word-wrap: break-word;
}

.notesListContainer {
  flex: 1;
  gap: 5px;
  overflow: scroll;
}

.editorContainer {
  padding: 10px;
  margin: 10px;
  text-align: right;
  background: #efefef;
  border-radius: $border-radius;
  align-self: flex-end;

  .saveBtn {
    margin-top: 5px;
  }

  .quill {
    background: white;
  }

  .ql-container {
    height: 120px;
    max-width: 370px;
  }
}

.note {
  background: #f2f2f2;
  border-radius: $border-radius;
  margin: 12px;
  padding: 8px;

  h1, h2, h3, ol, p {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.cardContainer {
  gap: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 24px;

  .card {
    border: $primary-background-color solid 0.5px;
    border-radius: $border-radius;
    background-color: #183c591e;
    display: flex;
    flex-direction: column;
    height: 200px;
    max-width: 250px;
    flex: 1;
    color: $primary-background-color;
    padding: 12px;

    .cardtitle {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    .cardvalue {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .cardpreviousvalue {
      font-size: 14px;
      font-weight: 400;
    }

    .cardvariation {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 12px;
    }
  }
}

.customerContact {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.customerAddresses {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.customerBlock {
  background-color: #183C59;
  color: white;
  font-size: 20px;
  font-weight: 700;
  border-radius: $border-radius;
  padding: 4px 0px 4px 12px;
  margin-bottom: 12px;
  text-align: left;
}

.importContainer {
  overflow: scroll;
  //height: calc(-#{$banner-height} + 100vh);
  flex: 1;
}

.importprops {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mapped {
    background-color: #e5faee;
    border: #9aeebc solid 1px;
    color: #0f5c2e;
    border-radius: 4px;
    padding: 5px;
    width: 500px;
  }

  .notmapped {
    background-color: #e8bdbe;
    border: #ce8888 solid 1px;
    color: #92030d;
    border-radius: 4px;
    padding: 5px;
    width: 500px;
  }

  .mapping_title {
    font-weight: 700;
  }

  .mapping_type {
    font-weight: 400;
  }
}

.importActions {
  float: right;
  width: 200px;
  margin-top: 20px;
  margin-right: 20px;
}

.errorContent {
  display: flex;
  flex-direction: column;
}

.errorTitle {
  font-weight: 700;
}

.noscroll {
  overflow: hidden !important;
}

.innerVerticalContent {
  display: block;
}

.channelgroupdesc {
 text-align: center;
 max-width: 700px;
 align-self: center;
}

.channelgroup {

}

.channelcontent {
  display: flex;
  flex-direction: column;
  flex: 1;
}
