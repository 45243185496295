@import "../../styles/base/variables";

.app-frame {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: clip;
  height: calc(100vh) !important;
}

.content-frame {
  height: calc(100vh);
  overflow: hidden;
  display: block;
  flex: 1;
}

.ribbon {
  flex: 1;
  background-color: $primary-background-color;
}

.app-content {
  height: calc(-#{$banner-height} + 100vh);
  border-top: $main-border;
  background: $background-color;
}

.toplogocontainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 80px;
}

.toplogocircle {
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  border-radius: 50%;
}

.menuimage {
  flex: 1 1 0;
  align-self: stretch;
}

.bottomcontainer {
  display: flex;
  justify-content: end;
  align-content: end;
}
