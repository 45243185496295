.assetthumbnailactions {
  position: absolute;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  right: 0;
  bottom: 0;
  background: red;
  gap: 4px;
}

.assetthumbnailcontainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
}

.assetCheckbox {
  position: relative;
}
