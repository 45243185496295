.debug-banner {
  /* stylelint-disable */
  &-container {
    position: fixed;
    top: 0;
    z-index: 2000;
    width: 250px;
    height: 20px;
    left: 50%;
    margin-left:-125px;
  }

  position: absolute;
  text-align: center;
  font-size: 14px;
  width: 100%;
  height: 16px;
  border-radius: 0px 0px 5px 5px;

  &-local {
    background-color: yellow;
  }

  &-dev {
    background-color: purple;
    color: white;
  }

  &-qa {
    background-color: teal;
    color: white;
  }

  &-uat {
    background-color: blue;
    color: white;
  }

  &-staging {
    background-color: orange;
  }

  &-web {
    background-color: green;
    color: white;
  }
  /* stylelint-enable */
}
