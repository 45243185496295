
.importscontainer {
  display: flex;
  flex-direction: column;
  height: calc(-120px + 100vh);
  gap: 12px;
  padding: 12px !important;
}

.importribbon {
  background: #FD936722;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between;
}
