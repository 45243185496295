@import "../../../styles/base/variables";

.leftcolumn {
  float: left;
  width: 220px;
  height: calc(-125px + 100vh);
  padding: 12px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightcolumn {
  height: calc(-155px + 100vh);
  flex: 1;
  overflow: scroll;

  div.header {
    padding: 12px 8px;
    font-size: 16px;
  }
}

.productimg {
  width: 100%;
  aspect-ratio: 1;
  border: #012F4455 1px solid;
  border-radius: 4px;
  overflow: hidden;
  object-fit: scale-down;
}

.productribbonbtns {
  width: 232px;
}

.thumbnail_container {
  position:relative;
  display:inline-block;
}

.thumbnail_image {
  position: relative;
  width: 100%;
  border-radius: 5px;
  border: #012F442f solid 1px;
}

.thumbnail_controls {
  position:absolute;
  bottom:10px;
  right:10px;
  display:inline-block;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

.productribbon {
  background: #FD936722;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left:12px;
}

.producthistory {
  width: 100%;
}

.tabcontent {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(-100px + 100vh);
}

.assetscontainer {
  display: flex;
  flex-direction: column;
  height: calc(-120px + 100vh);
}

.productdetail {
  display: flex;
  gap: $padding;
}

.productIdContainer {
  float: left;
  width: 240px;
  height: calc(-#{$banner-height} + 100vh);
  //background-color: $background-color;
  //padding-right: $padding;
  padding-bottom: $padding;
  //padding-top: $padding;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;

  div {
    background: white;
    //box-shadow: $box-shadow;
    border-radius: $border-radius;
  }

  div.header {
    padding: 12px 8px;
    font-size: 16px;
  }

  div.attributefilter {
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
  }

  div.field {
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;

    input {
      text-align: end;
      border: 0px;
      font-weight: 400;
    }
  }

  div.thumbnailcontainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%;

    .thumbnailactions {
      position: absolute;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      right: 0;
      bottom: 0;
      background: white;
      gap: 4px;
    }

    img {
      border-radius: $border-radius;
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
