.groupTitle {
    background-color: #012F4433;
    border-radius: 2px;
    padding: 2px 2px 2px 8px;
    margin-bottom: 12px;
}

.group {
    margin-bottom: 24px;
}

.groupheader {
  margin-bottom: 12px;
}
