.publicribbon {
  height: 50px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px 24px 36px;
}

.logocontainer {
  display: flex;
  background-color: #FFFFFF;
  justify-content: flex-start;
  align-items: center;
  padding-right: 5px;
}

.ribbonmenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  gap: 12px;
}

.ribbonlink {
  padding-left: 20px;
  cursor: pointer;
}

.logoimg {
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
}

.ribbontabs {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .ribbontabs {
    display: none;
  }
}
