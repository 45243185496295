@import "../../../styles/base/variables";

.productssearch {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 400px;
  height: 25px;
  border: #33183C59 solid 0.5px;
}

.propertytypes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 40px 10px;
}

.propertytype {
  height: 124px;
  width: 124px;
  border: $primary-background-color solid 0.5px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(337deg) brightness(105%) contrast(107%);
  }

  &:hover {
    background: $primary-background-color;
    color: white;
    img {
      filter: none;
    }
  }
}
