.accountBtn {
    max-width: 250px;
}

.accountBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountNames {
    align-items: center;
}

.accountName {
    font-size: 14px;
    color: #183C59;
    text-align: start;
    line-height: initial;
}

.accountEmail {
    font-size: 10px;
    font-weight: bold;
    color: #183C59;
    text-align: start;
}

@media only screen and (max-width: 600px) {
    .accountBtn {
        max-width: 40px;
    }
    .accountName {
      display: none;
    }
    .accountEmail {
      display: none;
    }
  }
