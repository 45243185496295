.property_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: #012F4422 solid 1px;
    margin-bottom: 8px;
    padding-bottom: 4px;
}

.property_name {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #6c6c6caa;
}

.property_value {
    width: 100%;
    text-align: start;
}

.property_action {
    display: flex;
    flex-direction: row;
}
