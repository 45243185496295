.addressEditor {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 450px;
}

.addressEditor-fieldset {
  text-align: left;
  margin: 0;
  padding: 0 8px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}
