@import "../../../styles/base/variables";

.filterContainer {
  float: left;
  width: 240px;
  height: calc(-#{$banner-height} + 100vh);
  //background-color: $background-color;
  padding-left: $padding;
  padding-bottom: $padding;
  padding-top: $padding;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;

  div {
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
  }

  div.header {
    padding: 12px 8px;
    font-size: 16px;
  }

  div.attributefilter {
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
  }

  div.field {
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;

    input {
      text-align: end;
      border: 0px;
      font-weight: 400;
    }
  }

  div.thumbnailcontainer {
    position: relative;
    width: 100%;

    img {
      border-radius: $border-radius;
      margin: auto;  /* Magic! */
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.assetthumbnail {
  width: 100%;
  height: 80%;
  border: #012F44 0.5px solid;
  border-radius: 4px;
}

.assetcontent {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.assetdropzone {
  display: block;
  width: 100%;
  height: 100px;
}
