@import "../../../styles/base/variables";

.productscontainer {
  display: flex;
  flex-direction: column;
  height: calc(-160px + 100vh);
  gap: 12px;
  padding: 12px !important;
}

//.productssearch {
//  padding: 2px 4px;
//  display: flex;
//  align-items: center;
//  width: 400px;
//  height: 25px;
//  border: #33183C59 solid 1px;
//}

.thumbnail {
  height: 30px;
  border: #012F4455 1px solid;
  border-radius: 4px;
}

.productlistribbon {
  background: #FD936722;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between;
}
