@import "../../../styles/base/variables";

.maincentercontainer {
  background-color: $background-color;
  height: calc(-#{$banner-height} + 100vh);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maincontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.smalllogoimg {
  height: 50px;
  margin-bottom: 12px;
}
