.selectLbl {
  font-size: 1.2em;
  font-weight: 400;
  margin: 16px;
}

.stackedButtonTitle {
  font-size: 1.8em;
  vertical-align: middle;
  margin: 8px;
  font-weight: 500;
}

.stackedButtonSubitle {
  font-size: 1.2em;
  vertical-align: middle;
}

.stackedButtonContent {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}

.stackedButton {
  border: #183C5944 solid 1px;
  background-color: #fafafa;
  padding: 8px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stackedButton:hover {
  border: #183C5944 solid 1px;
  background-color: #f5f5f5;
  padding: 8px;
  cursor: pointer;
}

.stackedButton:active {
  border: #183C5944 solid 1px;
  background-color: #ebebeb;
  padding: 8px;
}

.thumbnail {
  object-fit: cover;
  width: 80px;
  height: 80px;
}

.btnStack {
  display: flex;
  flex-direction: column;
}

.btnStack:first-child {
  display: flex;
  flex-direction: column;
}

.stackedButton:first-child {
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0px;
}

.stackedButton:last-child {
  border-top: 0px;
  border-radius: 0px 0px 4px 4px;
}

.btnStackContainer {
  position: absolute;
  text-align: center;
  left: 50%;
  width: 600px;
  margin-left: -200px;
}

