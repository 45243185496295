@import "../../../styles/base/variables";

.topcontainer {
  width: auto;
  height: $banner-height;
  background: $background-color;
  //border-bottom: $main-border;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.topleftcontainer {
  width: 72px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  gap: 64px;
  display: flex;
  background: $primary-background-color;
  box-shadow: 2px 0px 2px 0px #183C5944;
}

.topsearchcontainer {
  width: 148px;
  align-self: stretch;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
}

.topiconcontainer {
  width: 32px;
  height: 32px;
  position: relative;
}

.topsearch {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 400px;
  height: 25px;
  border: #33183C59 solid 0px;
}

.toprightcontainer {
  width: 232px;
  align-self: stretch;
  background: #F5F6F7;
  justify-content: flex-end;
  align-items: center;
  gap: 88px;
  display: flex;
  flex: 1;
}

.topbuttoncontainer {
  width: auto;
  align-self: stretch;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  display: flex;
}

.ribbontoplogocontainer {
  flex: 1;
  align-items: center;
  display: flex;
  height: 80px;
}

.ribbontoplogocircle {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  border-radius: 50%;
  border: $primary-background-color solid 0.5px;
  cursor: pointer;
}

.ribbonmenuimage {
  flex: 1 1 0;
  align-self: stretch;
}
