@import "../../../styles/base/variables";

.placeholdercontainer {
  height: calc(-#{$banner-height} + 100vh);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .smalllogoimg {
    height: 50px;
    margin-bottom: 12px;
  }
}

