@import "../../../styles/base/variables";

.menuContainer {
  display: flex;
  flex-direction: row;
  box-shadow: 2px 0px 2px 0px #183C5944;
}

.hiddenContainer {
  display: none !important;
}

.leftMenuContainer {
    width: 64px;
    //background-color: $background-color;
    background-color: $primary-background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px 8px 4px;
    height: calc(-#{$banner-height} + 100vh);
    //border-right: $main-border;

  ul {
    padding-inline-start: 0px;
  }

  li {
    list-style: none;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 13.2px */
    border-radius: 4px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      max-width: 24px;
      max-height: 24px;
    }

    p {
      margin-block-start: 4px;
      margin-block-end: 2px;
    }
  }

  li.separator {
    padding: 4px 0px 8px 0px;
    display: block;

    div {
      flex: 1 1 0;
      height: 2px;
      background: #C2C7D0;
      border-radius: 4px;
    }
  }

  li.menuItem {
    height: 52px;
    width: 52px;
    margin-bottom: 4px;
    align-content: center;
    color: white;

    img {
      color: white;
    }
  }

  li.menuItem:hover {
    background: lightgrey;
  }

  li.activeMenuItem {
    //background: $primary-background-color;
    background-color: $background-color;
    //color: white;
    color: $primary-background-color;
    height: 52px;
    width: 52px;
    margin-bottom: 4px;
    align-content: center;
    fill: white;

    img {
      filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(337deg) brightness(105%) contrast(107%);
    }
  }
}
